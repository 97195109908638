const ip_local='127.0.0.1';//本机
const ip_test='192.168.7.131';//测试内网
// const ip_test='222.177.234.65';//测试外网

// const type='local';
// const type='test';
const type='product';

// const title='老公货嘀运营管理平台';
const title='老公货嘀SaaS运营平台';

let config = {
    //系统访问上下文，在生产模式下，需要和后台的server.servlet.context-path配置保持一致
    contextPath:'/lghd',
    title:title,
    type:type,
    baseURL:type=='local'?('http://'+ip_local+':7788')
        :(
            type=='test'?
                (title=='老公货嘀运营管理平台'?('http://'+ip_test+':8009'):('http://'+ip_test+':8010'))
                :
                (title=='老公货嘀运营管理平台'?('https://yunying.suxiangkj.com'):('https://saas.suxiangkj.com'))
        ),
    //为app实例绑定全局属性（main和dialog处创建出实例之后会调用）
    bindAppProps:(app,props)=>{
        props.forEach(item=>{
            app.config.globalProperties[item.key] = item.value;
        });
    }
}
module.exports=config;